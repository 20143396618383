import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, ReportSchedulerDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-report-scheduler-item-form',
  templateUrl: './report-scheduler-item-form.component.html',
  styleUrl: './report-scheduler-item-form.component.scss',
})
export class ReportSchedulerItemFormComponent extends BaseForm<ReportSchedulerDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'REPORT_SCHEDULER');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null),
      // runAs: new FormControl(null),
      report: new FormControl(null),
      frequency: new FormControl(null),
      // reportFormat: new FormControl(null),
      sendEmailToStatic: new FormControl(null), //@TODO: make component for it like gmail ..etc
      emailDetails: new FormGroup({
        emailSubject: new FormControl(null),
        emailBody: new FormControl(null),
      }),
      accessedBy: new FormControl(null), //ADD PRINCIPLE SELECTOR
      sendEmailTo: new FormControl(null),
      reportTemplate: new FormControl(null),
      notes: new FormControl(null),
      // pdfrenderConfig: new FormControl(null),
    });
  }
  get emailDetailsForm() {
    return this.formGroup?.controls?.emailDetails as FormGroup;
  }
}
