<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'Report'"
        [placeholder]="'Search Records'"
        [control]="formGroup?.controls?.report"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'label'"
        [optionValue]="'code'"
        [targetTypes]="['REPORT']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-suggestion-input
        [label]="'Frequency'"
        [placeholder]="'Ex: 7 days'"
        [control]="formGroup?.controls?.frequency"
        [viewMode]="fieldViewMode"
        [items]="[
          { label: 'Yearly', value: 365 },
          { label: 'Quarterly', value: 90 },
          { label: 'Monthly', value: 30 },
          { label: 'Weekly', value: 7 },
          { label: 'Daily', value: 1 },
        ]"
        [type]="'number'"
        [min]="0"
        [maxFractionDigits]="0"
      >
      </app-suggestion-input>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'Report Configuration'"
        [placeholder]="'Search Records'"
        [control]="formGroup?.controls?.reportTemplate"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'label'"
        [optionValue]="'code'"
        [targetTypes]="['REPORT_CONFIGURATION_TEMPLATE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-12">
      <app-user-and-group-selector
        [label]="'Accessed By'"
        [placeholder]="'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.accessedBy"
        [optionValue]="undefined"
        [multi]="true"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="'Send Email To'"
        [placeholder]="'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.sendEmailTo"
        [optionValue]="undefined"
        [multi]="true"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <app-mail-to-input
      [name]="'sendEmailToStatic'"
      [control]="formGroup?.controls?.sendEmailToStatic"
      [viewMode]="fieldViewMode"
      class="wrapped-input-component"
    >
    </app-mail-to-input>
    <div class="col-12 md:col-12">
      <app-basic-input
        label="Email Subject"
        [placeholder]="'Enter Subject'"
        [control]="emailDetailsForm?.controls?.emailSubject"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'150px'"
        [name]="'textarea'"
        label="Email Details"
        [placeholder]="'Enter Text...'"
        [control]="emailDetailsForm?.controls?.emailBody"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'100px'"
        [name]="'textarea'"
        label="Notes"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.notes"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' && showSaveAndClose) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && showSaveAndClose) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
